export type AppState = {
  headerShown: boolean
  sidesheetCloseAllowed: boolean
}

export const useAppStore = defineStore('app', {
  state: (): AppState => ({
    headerShown: true,
    sidesheetCloseAllowed: true,
  }),

  getters: {
    isHeaderShown({ headerShown }) {
      return headerShown
    },
    isSidesheetCloseAllowed({ sidesheetCloseAllowed }) {
      return sidesheetCloseAllowed
    },
  },

  actions: {
    setHeaderShown(state: boolean) {
      this.headerShown = state
    },
    setSideSheetCloseAllowed(state: boolean) {
      this.sidesheetCloseAllowed = state
    },
  },
})
